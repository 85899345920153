import React from 'react'
import { useState } from 'react'
import PersonalInformationForm from './personalInfo'
import GeneralInformationForm from './generalInformation'
import PaneCount from './paneCount'
import ScreenCount from './screenCount'
import EndEstimator from './endEstimator'
import { Estimate } from '../models/models'
import Quote from './quote'
import CONSTANTS from '../constants'
import { Modal, ProgressBar } from 'react-bootstrap'
import { useEffect } from 'react'
import '../assets/css/home.css'

const Estimator = () => {

    const PERSONAL_INFORMATION = 1, GENERAL_INFORMATION = 2, PANE_COUNT = 3, SCREEN_COUNT = 4, END_ESTIMATOR = 5, QUOTE = 6
    const TOTAL_STEPS = 6

    const [page, setPage] = useState(PERSONAL_INFORMATION)

    const [estimate, setEstimate] = useState<Estimate>({
        Misc: {
            canEmail: true,
            canText: true
        },
        Customer: {
            area: CONSTANTS.SUMMERLIN
        },
        GeneralInformation: {
            howHearAboutUs: CONSTANTS.YELP,
            floorsAboveGround: CONSTANTS.FLOORS.ONE_STORY,
            basement: false,
            houseSize: CONSTANTS.HOUSESIZE.SMALLEST,
            residential: true
        }
    })

    const previousPage = () => {
        if (page > 0) {
            setPage(page - 1)
        }
    }

    const nextPage = () => {
        if (page < QUOTE) {
            setPage(page + 1)
        }
    }

    // Calculate progress percentage for the progress bar
    const progressPercentage = (page / TOTAL_STEPS) * 100

    return (
        <div className="estimator-container">
            <div className="progress-container px-4 pt-3">
                <ProgressBar 
                    now={progressPercentage} 
                    variant="info" 
                    style={{ 
                        height: '10px', 
                        backgroundColor: '#e0e0e0',
                        borderRadius: '10px'
                    }}
                />
                <p className="text-center mt-2 text-muted">Step {page} of {TOTAL_STEPS}</p>
            </div>
            
            {page == PERSONAL_INFORMATION && <PersonalInformationForm estimate={estimate} setEstimate={setEstimate} nextPage={nextPage} previousPage={previousPage} />}
            {page == GENERAL_INFORMATION && <GeneralInformationForm estimate={estimate} setEstimate={setEstimate} nextPage={nextPage} previousPage={previousPage} />}
            {page == PANE_COUNT && <PaneCount estimate={estimate} setEstimate={setEstimate} nextPage={nextPage} previousPage={previousPage} />}
            {page == SCREEN_COUNT && <ScreenCount estimate={estimate} setEstimate={setEstimate} nextPage={nextPage} previousPage={previousPage} />}
            {page == END_ESTIMATOR && <EndEstimator estimate={estimate} setEstimate={setEstimate} nextPage={nextPage} previousPage={previousPage} />}
            {page == QUOTE && <Quote estimate={estimate} previousPage={previousPage} />}
        </div>
    )
}

export default Estimator