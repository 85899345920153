import { url } from 'inspector'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Card, CardColumns, Col, Container, Image, Modal, Row } from 'react-bootstrap'
import Navbar from '../components/Navbar'
import '../App.css'
import TestimonialObject from '../objects/testimonialobject'
import Estimator from '../components/estimator'
import '../App.css'
import '../assets/css/home.css'
import CTA from '../components/CTA'
import Footer from '../components/Footer'
import ReactGA from 'react-ga'
import { Player } from 'video-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faDroplet,
    faCheckCircle,
    faTools,
    faHome,
    faPhone,
    faQuoteLeft,
    faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons'

import "../../node_modules/video-react/dist/video-react.css"; // import css
import firebase from 'firebase'

const PowerWashing = () => {

    const phoneNumberLink = "tel:7027470901"
    const phoneNumber = "(702) 747-0901"
    const discount = "20%"
    const [showModal, setShowModal] = useState(false)
    const [showDangerModal, setShowDangerModal] = useState(false)

    useEffect(() => {
        if (!firebase.apps.length) {
            // Initialize Cloud Firestore through Firebase
            firebase.initializeApp({
                apiKey: 'AIzaSyCrt_jTKOXaJU1dhclEXuWT7Q1bDu8_Vo8',
                authDomain: 'dephyned-web.firebaseapp.com',
                projectId: 'dephyned-web'
            });
        }
    }, [])

    const CallNow = () => {
        return (
            <div className="alert alert-info p-1">
                <div className="ml-5 mt-5">All you need to do is answer 3 simple questions and you'll receive your free estimate. And you can schedule the job also, all online.</div>
                <Button className="m-5" onClick={() => { window.location.href = "https://5ve5c9rmseo.typeform.com/to/TF2lznW8" }}>Click Here To Get Your Instant Quote</Button>
            </div>
        )
    }

    const Testimonial = (props: {
        testimonial: TestimonialObject
    }) => {
        return (
            <Card bg="light" className="mb-2">
                <Card.Header>{props.testimonial.username} says:</Card.Header>
                <Card.Body>
                    <div className="mb-3">
                        <Image src="/star.png" width="24" />
                        <Image src="/star.png" width="24" />
                        <Image src="/star.png" width="24" />
                        <Image src="/star.png" width="24" />
                        <Image src="/star.png" width="24" />
                    </div>
                    <Card.Title>{props.testimonial.title} </Card.Title>
                    <Card.Text>
                        {props.testimonial.content}
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }

    const AreYouSure = () => {
        return (
            <Modal show={true} size="sm" onHide={() => setShowDangerModal(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    If you go back you'll lose any information that you've entered. Are you sure you want to go back?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        setShowModal(false)
                        setShowDangerModal(false)
                    }} >Yes</Button>
                    <Button variant="success" onClick={() => setShowDangerModal(false)} >Cancel</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const markConversion = () => {
        ReactGA.event({
            category: 'User',
            action: 'User clicked phone number to call',
            label: "User clicked call button",
            value: 10
        })
    }

    return (
        <div className="no-printme" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {
                showDangerModal && <AreYouSure />
            }
            <Modal show={showModal} size="lg" onHide={() => setShowDangerModal(true)}>
                <Estimator />
            </Modal>
            <Navbar />

            <main style={{ width: "100%", fontSize: "18px", lineHeight: "2.0em", flex: "1 0 auto", display: "flex", flexDirection: "column" }} >
                <Container style={{ backgroundColor: "white", padding: 0 }} fluid>
                    <Row noGutters>
                        <Col className="image-text-overlay">
                            <Image src="/powerwash-poster.jpg" fluid style={{ width: "100%", height: "850px", objectFit: "cover" }} />

                            <div className="text-overlay">
                                <div className="header-links">
                                    <a href="/">Window Cleaning</a> {'|'} <a href="/powerwashing">Power Washing</a> {'|'} <a href="/car-detailing-las-vegas">Car Detailing</a> {'|'} <a href="/#screen-repair">Screen Repair</a>
                                </div>

                                <div className="text-wrapper">
                                    <h1 style={{ color: "#092a3c" }}>Las Vegas</h1>
                                    <h1 style={{ color: "#ff871e" }}>POWER WASHING!</h1>
                                    <Button className="pill-button left-align-button" href="https://5ve5c9rmseo.typeform.com/to/TF2lznW8">Get Your FREE Estimate Now!</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div className="content-container">
                        <Row className="mt-5">
                            <Col xs="12">
                                <h2 className="section-title mb-4">Best In Las Vegas Power Washing</h2>
                                <p className="text-center mb-5">
                                    We power wash <strong>driveways, patios, walkways, entrances, and exterior stucco.</strong>
                                    Our professional equipment and eco-friendly solutions deliver exceptional results that will transform your property.
                                </p>
                            </Col>
                        </Row>

                        <Row className="mb-5">
                            <Col md={6}>
                                <img
                                    src="power-washing/oil-before-after.jpg"
                                    alt="Power washing before and after in Las Vegas"
                                    className="img-fluid rounded shadow"
                                    style={{ width: '100%', objectFit: 'cover' }}
                                />
                            </Col>
                            <Col md={6} className="d-flex flex-column justify-content-center mt-4 mt-md-0">
                                <h3 style={{ color: '#074a5a' }}>Our Power Washing Services</h3>
                                <div className="numbered-list-container mt-3">
                                    <ul className="numbered-list">
                                        <li><strong>Stain removal</strong> from concrete, stone, and tile</li>
                                        <li><strong>Grease stains</strong> in your driveway from leaked automobile oil</li>
                                        <li><strong>Barbeque grill stains</strong> on your patio concrete</li>
                                        <li>Exterior <strong>tile</strong> including <strong>pool decks</strong></li>
                                        <li><strong>Exterior stucco</strong> on home or business walls</li>
                                        <li><strong>Stone</strong> walkways, driveways, and decorative stone</li>
                                        <li>Exterior <strong>concrete</strong> surfaces</li>
                                        <li>1-story &amp; 2-story buildings</li>
                                        <li>Residential and commercial properties</li>
                                        <li>Luxury and non-luxury homes</li>
                                    </ul>
                                </div>
                                <p className="mt-3"><strong>* Licensed and Insured up to $1 million</strong></p>
                            </Col>
                        </Row>

                        <div className="modern-cta">
                            <h2 className="modern-cta-title">Ready to Transform Your Property?</h2>
                            <p>Get your instant quote now and see the difference professional power washing makes!</p>
                            <div>
                                {/* <Button className="modern-cta-button" onClick={() => { window.location.href = "https://5ve5c9rmseo.typeform.com/to/TF2lznW8" }}>
                                    <FontAwesomeIcon icon={faQuoteLeft} className="mr-2" /> Get Your Instant Quote
                                </Button> */}
                                <Button className="modern-cta-button" href={phoneNumberLink} onClick={() => { markConversion() }}>
                                    <FontAwesomeIcon icon={faPhone} className="mr-2" /> Call {phoneNumber}
                                </Button>
                            </div>
                        </div>

                        <Row className="mt-5">
                            <Col xs="12">
                                <h2 className="section-title mb-5">Our Work in Las Vegas</h2>
                                <div className="gallery-container">
                                    <div className="gallery-card">
                                        <img src="power-washing/driveway-before-after.jpg" alt="Driveway cleaning in Las Vegas" />
                                        <div className="gallery-card-body">
                                            This driveway was filthy and had never been cleaned before. Look at how nice it looked after we were done.
                                        </div>
                                    </div>
                                    <div className="gallery-card">
                                        <img src="power-washing/dirty-clean.jpg" alt="Custom concrete cleaning in Las Vegas" />
                                        <div className="gallery-card-body">
                                            Custom painted cement that needed cleaning without damage. The concrete was awful before, but the customer was thrilled with the results.
                                        </div>
                                    </div>
                                    <div className="gallery-card">
                                        <img src="power-washing/tires-before-after.jpg" alt="Tire mark removal in Las Vegas" />
                                        <div className="gallery-card-body">
                                            Tire marks can really ruin your driveway's appearance. We can remove these stubborn marks and make your driveway look brand new again.
                                        </div>
                                    </div>
                                    <div className="gallery-card">
                                        <img src="power-washing/trash-before-after.jpg" alt="Trash bin cleaning in Las Vegas" />
                                        <div className="gallery-card-body">
                                            We also clean dirty trash bins to prevent odors and keep your property looking pristine.
                                        </div>
                                    </div>
                                    <div className="gallery-card">
                                        <img src="power-washing/oil-before-after.jpg" alt="Oil stain removal in Las Vegas" />
                                        <div className="gallery-card-body">
                                            Oil stains are not just unsightly, they can also lead to HOA fines. Our equipment can effectively remove these stubborn stains.
                                        </div>
                                    </div>
                                    <div className="gallery-card">
                                        <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
                                            <Player
                                                playsInline
                                                poster="power-washing/clean-tires.jpg"
                                                src="power-washing/cleaning-tires.mp4"
                                                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                            />
                                        </div>
                                        <div className="gallery-card-body">
                                            Watch our pressure washing machine strip away tire marks, leaving the concrete beautifully clean.
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="section-separator">
                            <Col xs="12" id="service-areas">
                                <div className="section-heading-with-icon">
                                    <div className="icon-circle">
                                        <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />
                                    </div>
                                    <h2 className="section-title mb-0 mt-5">We Serve Las Vegas, Henderson, and Boulder City</h2>
                                </div>
                                <hr />

                                <p>Driveway tire marks and oil stains are unsightly and can be embarrassing for homeowners who take pride in their property's appearance.</p>

                                <p>We understand that you care about the beauty of your home, both inside and out. That's why we work diligently to ensure that your driveway, patio, walkway, and home exterior look immaculate after our power washing services.</p>

                                <div className="numbered-list-container">
                                    <ul className="numbered-list">
                                        <li><strong>Eco-Friendly Solutions:</strong> Besides water, we always use natural, environmentally-friendly products</li>
                                        <li><strong>Thorough Cleaning:</strong> We power wash every inch of the surface until it looks rejuvenated</li>
                                        <li><strong>Pet-Friendly:</strong> All our cleaning solutions are safe for homes with pets</li>
                                        <li><strong>Complete Service:</strong> We don't stop until your stone, concrete, tile, or stucco looks like new</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>

                        <Row className="section-separator">
                            <Col xs="12" id="equipment">
                                <div className="section-heading-with-icon">
                                    <div className="icon-circle">
                                        <FontAwesomeIcon icon={faDroplet} size="lg" />
                                    </div>
                                    <h2 className="section-title mb-0">Professional-Grade Equipment for Tough Stains</h2>
                                </div>
                                <hr />

                                {/* <div className="my-5 d-flex justify-content-center">
                                    <Image src="power-washing/stucco-before.jpg" rounded className="img-fluid shadow" />
                                </div> */}

                                <p>Our commercial-grade equipment delivers <strong>up to 4,000 pounds per square inch of pressure</strong> to effectively clean your home's stucco, concrete, stone, and tile surfaces.</p>

                                <p>We also use special eco-friendly, <strong>pet-friendly power-washing solutions</strong> that help us tackle the toughest stains in concrete, stone, and tile without harming the environment or your family.</p>

                                <h4 style={{ color: '#074a5a' }}>Las Vegas Power Washing Experts</h4>

                                <p>As local professionals, we can remove stains that DIY methods simply can't touch. We promise to make the concrete, stucco, tile, or stone around your home <strong>look fantastic</strong> - often restoring it to a condition that looks almost new!</p>
                            </Col>
                        </Row>

                        <div id="screen-repair" className="section-separator">
                            <Row style={{ marginBottom: 0 }}>
                                <Col xs="12" md="6">
                                    <div>
                                        <div className="section-heading-with-icon">
                                            <div className="icon-circle">
                                                <FontAwesomeIcon icon={faTools} size="lg" />
                                            </div>
                                            <h2 className="section-title mb-0">Window Screen Repair and Installation</h2>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <h5><FontAwesomeIcon icon={faCheckCircle} className="elegant-icon" /> We construct and install window screens.</h5>
                                    </div>
                                    <div className="mt-4">
                                        Because of the intense Las Vegas sun, window screens fade over time and become brittle. Even the frames themselves can become brittle and the corners can easily break.
                                    </div>

                                    <div>
                                        <strong><FontAwesomeIcon icon={faCheckCircle} className="elegant-icon" /> If you have...</strong>
                                        <div className="numbered-list-container">
                                            <ul className="numbered-list">
                                                <li><strong>Damaged:</strong> Old or bent screen frames</li>
                                                <li><strong>Worn:</strong> Faded or torn window screens</li>
                                                <li><strong>Missing:</strong> Windows that need new screens installed</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div>
                                        ...then call us now for your <strong>FREE at home estimate.</strong> We will come out, measure all your windows and give you your personalized quote.
                                    </div>
                                    <div className="text-center mb-4 mt-4">
                                        <Button className="modern-cta-button" href={phoneNumberLink} onClick={() => { markConversion() }}>
                                            <FontAwesomeIcon icon={faPhone} className="elegant-icon-white" /> Call {phoneNumber}
                                        </Button>
                                    </div>
                                </Col>
                                <Col xs="12" md="6" className="text-center my-4 d-flex align-items-center">
                                    <Image src="/screen-rebuild.jpg" className="img-fluid rounded shadow" />
                                </Col>
                            </Row>
                        </div>
                    </div> {/* End of content-container */}
                </Container>
            </main>
            <Footer />
        </div >
    )
}

export default PowerWashing