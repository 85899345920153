import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Image, Modal, CardColumns, Button, Row, Col, Alert } from 'react-bootstrap'
import CONSTANTS from '../constants'
import { Estimate } from '../models/models'
import Package from './package'
import './quote.css'

type Pricing = {
    basicBoth?: number,
    basicExt?: number,
    driveway?: number,
    exteriorHouseWashing?: number,
    screenRepair?: number,
    screenBuilding?: number,
    blinds?: number
}

type SelectedService = {
    service: string,
    price: number,
    amount?: number
}

const Quote = (props: { estimate: Estimate, previousPage: any }) => {

    /** The pricing for each service that we offer */
    const [pricing, setPricing] = useState<Pricing>({})

    /** The main service that the user has selected */
    const [mainService, setMainService] = useState<SelectedService>()

    /** The list of services that the user has selected. Not including the main service. */
    const [services, setServices] = useState<SelectedService[]>([])

    /** The service object for screen repair */
    const [screenRepairService, setScreenRepairService] = useState<SelectedService>({
        service: CONSTANTS.SCREENS.REPAIR,
        price: 0
    })

    /** The service object for screen building */
    const [screenBuildingService, setScreenBuildingService] = useState<SelectedService>({
        service: CONSTANTS.SCREENS.BUILDING,
        price: 0
    })

    const [blindsService, setBlindsService] = useState<SelectedService>({
        service: CONSTANTS.BLINDS,
        price: 0
    })

    /** Whether or not to show the modal explaining to the user the dangers of going back */
    const [showPreviousModal, setShowPreviousModal] = useState(false)

    const [finished, setFinished] = useState(false)

    const [error, setError] = useState("")

    const [processing, setProcessing] = useState(false)

    const [noPackage, setNoPackage] = useState(false)

    const updateMainService = (service: SelectedService) => {

        if (mainService?.service == service.service) {
            setMainService(undefined)
            return
        }

        setMainService(service)
    }

    const getHouseSize = () => {

        if (!props.estimate.GeneralInformation?.houseSize) { return }

        switch (props.estimate.GeneralInformation.houseSize) {
            case CONSTANTS.HOUSESIZE.SMALLEST:
                return 1600
            case CONSTANTS.HOUSESIZE.SMALLER:
                return 1800
            case CONSTANTS.HOUSESIZE.SMALL:
                return 2300
            case CONSTANTS.HOUSESIZE.MEDIUM:
                return 2800
            case CONSTANTS.HOUSESIZE.MEDIUMLARGE:
                return 3300
            case CONSTANTS.HOUSESIZE.LARGE:
                return 3800
            case CONSTANTS.HOUSESIZE.VERYLARGE:
                return 4500
            case CONSTANTS.HOUSESIZE.HUGE:
                return 5000
            default:
                return 0
        }
    }

    const getHouseStories = () => {
        if (!props.estimate.GeneralInformation?.floorsAboveGround) { return }

        switch (props.estimate.GeneralInformation.floorsAboveGround) {
            case CONSTANTS.FLOORS.ONE_STORY:
                return 1
            case CONSTANTS.FLOORS.TWO_STORY:
                return 2
            case CONSTANTS.FLOORS.THREE_STORY:
                return 3
            default:
                return 0
        }
    }

    useEffect(() => {

        let panes = props.estimate.Panes
        let screens = props.estimate.Screens

        if (!panes || !screens) { return }

        let paneCount = (panes.small ?? 0) + (panes.medium ?? 0) + (panes.large ?? 0) + (panes.veryLarge ?? 0)

        let screenPrice = 0
        screenPrice = Math.ceil((paneCount / 2.2) * 3)
        screenPrice = screenPrice + ((screens.sun ?? 0) * 10)

        let windowCleaningBoth = (((panes.small ?? 0) * 7.0 + (panes.medium ?? 0) * 9.0 + (panes.large ?? 0) * 9 + (panes.veryLarge ?? 0) * 10) ?? 0) + screenPrice

        let windowCleaningExt = (((panes.small ?? 0) * 4.0 + (panes.medium ?? 0) * 5.0 + (panes.large ?? 0) * 6 + (panes.veryLarge ?? 0) * 7) ?? 0) + screenPrice

        if (getHouseStories() === 2) {
            windowCleaningExt = windowCleaningExt * 1.5
            windowCleaningBoth = windowCleaningBoth * 1.5
        } else if (getHouseStories() == 3) {
            windowCleaningExt = windowCleaningExt * 2.2
            windowCleaningBoth = windowCleaningBoth * 2.2
        }

        let myPricing = pricing
        myPricing.basicBoth = windowCleaningBoth
        myPricing.basicExt = windowCleaningExt
        myPricing.driveway = (getHouseSize() ?? 0) * .05
        myPricing.exteriorHouseWashing = ((getHouseSize() ?? 0) * .2) * (getHouseStories() ?? 0)
        myPricing.blinds = paneCount * 3

        setBlindsService({
            service: CONSTANTS.BLINDS,
            price: myPricing.blinds
        })

        setPricing((prevState) => ({
            ...prevState,
            basicBoth: windowCleaningBoth,
            basicExt: windowCleaningExt,
            driveway: myPricing.driveway,
            exteriorHouseWashing: myPricing.exteriorHouseWashing,
            blinds: myPricing.blinds
        }))

    }, [props.estimate])

    const screensToRepair = (numOfScreens: string) => {
        let myPricing = pricing

        myPricing.screenRepair = 25 * parseInt(numOfScreens)
        setPricing((prevState) => ({
            ...prevState,
            screenRepair: myPricing.screenRepair
        }))

        setScreenRepairService({
            service: CONSTANTS.SCREENS.REPAIR,
            price: myPricing.screenRepair,
            amount: parseInt(numOfScreens)
        })
    }

    const screensToBuild = (numOfScreens: string) => {
        let myPricing = pricing

        myPricing.screenBuilding = 50 * parseInt(numOfScreens)
        setPricing((prevState) => ({
            ...prevState,
            screenBuilding: myPricing.screenBuilding
        }))

        setScreenBuildingService({
            service: CONSTANTS.SCREENS.BUILDING,
            price: myPricing.screenBuilding,
            amount: parseInt(numOfScreens)
        })
    }

    const addService = (service: SelectedService) => {

        if (serviceSelected(service)) {
            removeService(service)
            return
        }

        setServices(services => [...services, service])
    }

    const removeService = (service: SelectedService) => {
        let myServices = [...services]
        let index = myServices.map(s => s.service).indexOf(service.service)
        myServices.splice(index, 1)
        setServices(myServices)
    }

    const serviceSelected = (service: SelectedService) => {
        let myServices = services

        for (let index = 0; index < services.length; index++) {
            const s = services[index];

            if (s.service == service.service) {
                return true
            }
        }

        return false
    }

    const getAddOnsPrice = () => {
        return services.reduce((a, b) => a + b.price, 0)
    }

    const AreYouSure = () => {
        return (
            <Modal show={true} size="lg" onHide={() => setShowPreviousModal(false)} backdrop="static" centered>
                <Modal.Header closeButton className="border-0">
                    <div className="section-heading-with-icon w-100 justify-content-center">
                        <h2 className="section-title mb-0">Are you sure?</h2>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4 text-center">
                    <div className="numbered-list-container my-3">
                        <p>If you go back, you'll lose any information you've entered on this page.</p>
                        <p className="mt-3 mb-0">Are you sure you want to go back?</p>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-center pb-4">
                    <Button
                        className="modern-cta-button mr-3"
                        style={{ backgroundColor: "#074a5a", color: "white" }}
                        onClick={() => setShowPreviousModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        className="modern-cta-button"
                        onClick={props.previousPage}>
                        Yes, Go Back
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const NoPackageSelected = () => {
        return (
            <Modal show={true} size="lg" onHide={() => setNoPackage(false)} backdrop="static" centered>
                <Modal.Header closeButton className="border-0">
                    <div className="section-heading-with-icon w-100 justify-content-center">
                        <h2 className="section-title mb-0">Please Select a Service</h2>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4 text-center">
                    <div className="numbered-list-container my-3">
                        <p>In order to book, you must select at least one service.</p>
                        <p className="mt-3 mb-0">Please choose a window cleaning option or add-on service.</p>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-center pb-4">
                    <Button
                        className="modern-cta-button"
                        onClick={() => setNoPackage(false)}>
                        Okay, I'll Select a Service
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const sendQuote = () => {

        if (!mainService && services.length == 0) {
            setNoPackage(true)
            return
        }

        setProcessing(true)

        let estimate: any = props.estimate
        estimate.mainService = mainService
        estimate.services = services

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(estimate)
        }

        fetch("https://defined-cleaning.herokuapp.com/sendMessage", requestOptions)
            .then(data => {
                console.log("Finished")
                setFinished(true)
                setProcessing(false)
            })
            .catch(error => setError(error.message))
    }

    const Quote = () => {
        return (
            <div className="printme" >
                {
                    noPackage && <NoPackageSelected />
                }
                <div className="mb-4">
                    {/* PERSONAL INFORMATION */}
                    <Row>
                        <Col xs="10">
                            <strong>Personal Information</strong>
                            <div className="mt-2">{props.estimate.Customer?.firstName} {props.estimate.Customer?.lastName}</div>
                            <div>{props.estimate.Customer?.address}, {props.estimate.Customer?.zipCode}, {props.estimate.Customer?.area}</div>
                            <div>{props.estimate.Customer?.phoneNumber}</div>
                            <div>{props.estimate.Customer?.email}</div>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col xs="10">
                            <strong>General Information</strong>
                            <div className="mt-2">{props.estimate.GeneralInformation?.floorsAboveGround}</div>
                            <div className="mt-2">{props.estimate.GeneralInformation?.houseSize}</div>
                            <div className="mt-2">{props.estimate.GeneralInformation?.residential == true ?
                                "Residential" : "Commercial"}</div>
                            <div className="mt-3"><strong>NOTES:</strong> </div>
                            {props.estimate.Misc?.additionalInfo}
                        </Col>
                    </Row>
                    <hr />
                    <div className="my-4">
                        <h5>THE SERVICES YOU'VE SELECTED:</h5>
                    </div>

                    <div className="my-2"><strong>Services</strong></div>

                    {
                        mainService &&
                        <div>
                            <Row className="my-2">
                                <Col xs="10">
                                    <div>
                                        <strong>• {mainService?.service}</strong>
                                    </div>
                                    <div className="ml-4">
                                        Window Panes x {(props.estimate.Panes?.small ?? 0) + (props.estimate.Panes?.medium ?? 0) + (props.estimate.Panes?.large ?? 0) + (props.estimate.Panes?.veryLarge ?? 0)}
                                    </div>
                                    <div className="ml-4">
                                        Window Screens x {
                                            (props.estimate.Screens?.normal ?? 0) + (props.estimate.Screens?.sun ?? 0)
                                        }
                                    </div>

                                </Col>
                                <Col xs="2">
                                    <strong>${mainService?.price ?? 0}</strong>
                                </Col>
                            </Row>
                        </div>
                    }
                    {
                        services.map(s => {
                            return (
                                <Row>
                                    <Col xs="10">
                                        <div><strong>• {s.service} </strong>{!s.amount ? "" : `x ${s.amount}`}</div>
                                    </Col>
                                    <Col xs="2">
                                        <strong>${s.price}</strong>
                                    </Col>
                                </Row>
                            )
                        })
                    }
                    <hr />
                    {/* TOTAL PRICE */}
                    <Row>
                        <Col xs="10">
                            <strong>Total Price</strong>
                        </Col>
                        <Col xs="2">
                            <strong>${getAddOnsPrice() + (mainService?.price ?? 0)}</strong>
                        </Col>
                    </Row>



                </div>
            </div>
        )
    }

    const print = () => {
        window.print()
    }

    const scheduleAppointment = () => {
        let totalPrice = getAddOnsPrice() + (mainService?.price ?? 0)

        if (totalPrice < 150) {
            // Job should take about 1 hour
            window.location.replace("https://app.acuityscheduling.com/schedule.php?owner=20558893&appointmentType=16635721")
        } else if (totalPrice < 200) {
            // Job should take about 2 hours
            window.location.replace("https://app.acuityscheduling.com/schedule.php?owner=20558893&appointmentType=39980877")
        } else if (totalPrice < 300) {
            // Job should take about 3 hours
            window.location.replace("https://app.acuityscheduling.com/schedule.php?owner=20558893&appointmentType=40345092")
        } else if (totalPrice < 400) {
            // Job should take about 4 hours
            window.location.replace("https://app.acuityscheduling.com/schedule.php?owner=20558893&appointmentType=40345584")
        } else if (totalPrice < 500) {
            // Job should take about 5 hours
            window.location.replace("https://app.acuityscheduling.com/schedule.php?owner=20558893&appointmentType=40345587")
        } else {
            // Job should take about 6 hours
            window.location.replace("https://app.acuityscheduling.com/schedule.php?owner=20558893&appointmentType=40345591")
        }
    }

    return (
        <div>
            {
                finished &&
                <div>
                    <Modal.Header className="no-printme border-0">
                        <div className="section-heading-with-icon w-100 justify-content-center">
                            <h2 className="section-title mb-0">Thank You!</h2>
                        </div>
                        <Button className="modern-cta-button ml-3 no-printme" onClick={print}>
                            Print Quote
                        </Button>
                    </Modal.Header>
                    <Modal.Body className="px-4">

                        <div className="mb-4 no-printme text-center">
                            <p>Thank you so much for reaching out to us! Please click 'Schedule Appointment' below and schedule a date and time for your appointment.</p>
                        </div>
                        <div className="d-flex justify-content-center no-printme mb-4">
                            <Button className="modern-cta-button" onClick={scheduleAppointment}>
                                Schedule Appointment
                            </Button>
                        </div>
                        <hr className="no-printme" />
                        <Quote />
                    </Modal.Body>
                </div>
            }
            {
                !finished &&
                <div>
                    <Modal.Header closeButton className="border-0">
                        <div className="section-heading-with-icon w-100 justify-content-center">
                            <h2 className="section-title mb-0">Your Quote</h2>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="px-4">

                        {
                            pricing.basicExt != 0 &&
                            <div>
                                <h4 className="text-center my-3" style={{ color: "#074a5a" }}>Window Cleaning</h4>
                                <div className="numbered-list-container my-3">
                                    <p className="text-center">
                                        <i>We know that you've done your best making sure that the information is as accurate as possible. But mistakes happen. Before we start on your home, we'll walk around to make sure that the information entered is accurate to a reasonable degree. If we find that the information entered is off by a large degree then we'll let you know the price before we begin. 😁</i>
                                    </p>
                                </div>
                                <Alert style={{ textAlign: "center" }} className="mt-3" variant="success">
                                    <h4>Book your cleaning for before {moment().add(7, 'd').format('MMMM Do')} and get 20% off</h4>
                                    <div>The prices below do not reflect the 20% off. After you have filled out the form and scheduled, print out the confirmation. When you show us the confirmation form on the day we arrive to clean your windows we will take 20% off of the price.</div>
                                </Alert>
                                <hr />
                                <div className="row mb-3">
                                    <div className="col-md-6 mb-4">
                                        <Package
                                            header={CONSTANTS.WINDOWS.EXTERIOR}
                                            services={[<span>Windows cleaned <strong>outside only</strong></span>, "Screens removed and brushed and cleaned with soap and water", "$1 Million insurance protection", "Window tracks cleaned", "Frames and sills wiped down"]}
                                            buttonPressed={() => updateMainService(
                                                {
                                                    service: CONSTANTS.WINDOWS.EXTERIOR,
                                                    price: pricing.basicExt ?? 0
                                                })
                                            }
                                            price={pricing.basicExt ?? 0}
                                            selected={mainService?.service == CONSTANTS.WINDOWS.EXTERIOR}
                                            color="primary"
                                        ></Package>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <Package
                                            header={CONSTANTS.WINDOWS.BOTH}
                                            services={[<span>Windows cleaned <strong>inside and outside</strong></span>, "Screens removed and brushed and cleaned with soap and water", "$1 Million insurance protection", "Window tracks cleaned", "Frames and sills wiped down"]}
                                            buttonPressed={() => updateMainService(
                                                {
                                                    service: CONSTANTS.WINDOWS.BOTH,
                                                    price: pricing.basicBoth ?? 0
                                                })
                                            }
                                            price={pricing.basicBoth ?? 0}
                                            selected={mainService?.service == CONSTANTS.WINDOWS.BOTH}
                                            color="primary"
                                        ></Package>
                                    </div>
                                </div>
                            </div>
                        }

                        <h4 className="text-center my-3" style={{ color: "#074a5a" }}>Power Washing</h4>
                        <hr />
                        <div className="row">
                            <div className="col-md-6 mb-4">
                                <Package
                                    header={CONSTANTS.POWERWASH.DRIVEWAY}
                                    services={["Using a power washer we remove any tire marks and dirt and grime from your driveway. "]}
                                    buttonText="Add Service"
                                    buttonPressed={() => {
                                        addService({
                                            service: CONSTANTS.POWERWASH.DRIVEWAY,
                                            price: pricing.driveway ?? 0
                                        })
                                    }}
                                    selected={
                                        serviceSelected({
                                            service: CONSTANTS.POWERWASH.DRIVEWAY,
                                            price: pricing.driveway ?? 0
                                        })
                                    }
                                    price={pricing.driveway ?? 0}
                                    color="success" />
                            </div>
                            <div className="col-md-6 mb-4">
                                <Package
                                    header="Exterior House Washing"
                                    services={["Using a low-pressure washer and specialized solutions we safely remove stains and gunk from the outside of your home."]}
                                    buttonText="Add Service"
                                    buttonPressed={() => {
                                        addService({
                                            service: CONSTANTS.POWERWASH.HOME,
                                            price: pricing.exteriorHouseWashing ?? 0
                                        })
                                    }}
                                    selected={
                                        serviceSelected({
                                            service: CONSTANTS.POWERWASH.HOME,
                                            price: pricing.exteriorHouseWashing ?? 0
                                        })
                                    }
                                    price={pricing.exteriorHouseWashing ?? 0}
                                    color="success" />
                            </div>
                        </div>

                        <h4 className="text-center my-3" style={{ color: "#074a5a" }}>Window Screen Repair and Rebuilding</h4>
                        <hr />
                        <div className="row">
                            {/* SCREEN REPAIR */}
                            <div className="col-md-6 mb-4">
                                <Package
                                    header="Screen Repair"
                                    services={["Do you have any screens that are faded or torn that need to be repaired?", "Let us know how many screens need to be repaired and we can repair them at the appointment."]}
                                    textFieldPlaceholder="Enter the amount of screens to repair"
                                    textFieldUpdated={screensToRepair}
                                    price={pricing.screenRepair}
                                    buttonText="Add Service"
                                    buttonPressed={() => {
                                        addService(screenRepairService)
                                    }}
                                    selected={
                                        serviceSelected(screenRepairService)
                                    }
                                    color="warning" />
                            </div>

                            {/* SCREEN BUILDING */}
                            <div className="col-md-6 mb-4">
                                <Package
                                    header="Screen Building"
                                    services={["Do you have any windows that are missing screens? Do you have any window screen frames that are badly bent or broken?", "Let us know how many screens you need to be built and we can build them at the appointment."]}
                                    textFieldPlaceholder="Enter the amount of screens to build"
                                    textFieldUpdated={screensToBuild}
                                    price={pricing.screenBuilding}
                                    buttonText="Add Service"
                                    buttonPressed={() => {
                                        addService(screenBuildingService)
                                    }}
                                    selected={
                                        serviceSelected(screenBuildingService)
                                    }
                                    color="warning" />
                            </div>
                        </div>

                        {
                            pricing.blinds != 0 &&
                            <div>
                                <h4 className="text-center my-3" style={{ color: "#074a5a" }}>Blind Cleaning</h4>
                                <hr />

                                <div className="row">
                                    <div className="col-md-6 mb-4 mx-auto">
                                        <Package
                                            header="Clean Blinds"
                                            services={["Are your windows covered by blinds? If so, you should get them cleaned regularly as well. Dirty blinds can detract from your clean windows."]}
                                            price={pricing.blinds}
                                            buttonText="Add Service"
                                            buttonPressed={() => {
                                                addService(blindsService)
                                            }}
                                            selected={
                                                serviceSelected(blindsService)
                                            }
                                            color="info" />
                                    </div>
                                </div>
                            </div>
                        }
                        <hr />
                        <div className="numbered-list-container my-4">
                            <Quote />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-0 justify-content-center">
                        <Button
                            className="modern-cta-button mr-2"
                            style={{ backgroundColor: "#074a5a", color: "white" }}
                            onClick={() => setShowPreviousModal(true)}>
                            Previous
                        </Button>
                        {
                            processing && <Image src="loading.gif" />
                        }
                        {
                            !processing && !finished &&
                            <Button
                                className="modern-cta-button ml-2"
                                onClick={() => sendQuote()}>
                                I'm Ready To Book
                            </Button>
                        }
                    </Modal.Footer>
                </div>
            }

            {
                showPreviousModal && <AreYouSure />
            }
        </div >
    )
}

export default Quote