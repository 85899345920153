import React, { useEffect, useState } from 'react'
import { Card, Button, FormControl, Form } from 'react-bootstrap'
import './package.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import '../assets/css/home.css'

const Package = (props: {
    header: string,
    services: any[],
    price?: number,
    color?: string,
    textFieldUpdated?: any,
    buttonText?: string,
    buttonPressed?: any,
    textFieldPlaceholder?: string,
    selected?: boolean
}) => {

    const [styleClassName, setStyleClassName] = useState("")
    
    // Map color names to brand colors
    const getColorValue = (colorName: string) => {
        switch(colorName) {
            case 'primary': return '#074a5a';
            case 'success': return '#28a745';
            case 'warning': return '#ff871e';
            case 'info': return '#17a2b8';
            default: return '#074a5a';
        }
    };

    useEffect(() => {
        if (props.selected) {
            setStyleClassName("selected-package")
            return
        }

        setStyleClassName("white-bg")
    }, [props.selected])

    const color = props.color ?? "primary";
    const brandColor = getColorValue(color);
    
    return (
        <Card className="package-card h-100">
            <Card.Header style={{ textAlign: "center", color: "white", backgroundColor: brandColor, borderBottom: 0 }}>
                <h4 className="mb-0">{props.header}</h4>
            </Card.Header>
            <Card.Body className={props.selected ? "selected-package" : ""} >
                <Card.Text>
                    {
                        props.services.map((s, index) => {
                            return (
                                <div key={index} className="service-item">
                                    <div className="service-bullet">•</div>
                                    <div className="service-text">{s}</div>
                                </div>
                            )
                        })
                    }
                </Card.Text>
                {
                    props.textFieldPlaceholder &&
                    <FormControl 
                        min={0} 
                        type="number"
                        className="rounded-pill my-3"
                        disabled={props.selected == true} 
                        onChange={(e) => props.textFieldUpdated(e.target.value)} 
                        placeholder={props.textFieldPlaceholder} />
                }
                {
                    props.price &&
                    <div className="text-center mt-4 price-container">
                        <h2 style={{ color: brandColor, fontFamily: "'Lemonmilk', Arial, sans-serif" }}>${props.price}</h2>
                    </div>
                }
            </Card.Body>
            <Card.Footer style={{ backgroundColor: "transparent", textAlign: "center", border: 0 }}>
                <Button 
                    onClick={props.buttonPressed} 
                    className={props.selected ? "modern-cta-button selected-button" : "modern-cta-button"}
                    style={{ backgroundColor: props.selected ? "#074a5a" : brandColor }}
                >
                    {
                        !props.selected ? (props.buttonText ?? "Select Service") :
                            <><FontAwesomeIcon icon={faCheck} /> Selected</>
                    }
                </Button>
            </Card.Footer>
        </Card>
    )
}

export default Package