import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import Navbar from '../components/Navbar';
import '../App.css';
import '../assets/css/home.css';
import Footer from '../components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSun,
    faCheckCircle,
    faShieldAlt,
    faPhone,
    faQuoteLeft,
    faAngleRight,
    faEnvelope,
    faHome,
    faMapMarkerAlt,
    faTools,
    faDroplet,
    faLeaf,
    faHandSparkles,
    faWindowMaximize
} from '@fortawesome/free-solid-svg-icons';

const SolarScreens = () => {
    const phoneNumberLink = "tel:7027470901";
    const phoneNumber = "(702) 747-0901";

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Navbar />

            <main style={{ width: "100%", fontSize: "18px", lineHeight: "2.0em", flex: "1 0 auto", display: "flex", flexDirection: "column" }}>
                <Container style={{ backgroundColor: "white", padding: 0 }} fluid>
                    {/* Hero Section */}
                    <Row className="py-5">
                        <Col xs={12} className="text-center">
                            <div className="section-heading-with-icon">
                                <div className="icon-circle">
                                    <FontAwesomeIcon icon={faSun} size="lg" />
                                </div>
                                <h1 className="section-title mb-0">Solar Screen Installation in Las Vegas</h1>
                            </div>
                            <p className="mt-4">Beat the desert heat with custom solar screens that block 80-90% of the sun's heat and glare</p>
                        </Col>
                    </Row>

                    <div className="content-container">
                        {/* Main Image Section */}
                        <Row className="mb-5">
                            <Col xs={12} className="d-flex justify-content-center">
                                <Image src="/best-solar-screen-image.png" fluid className="rounded shadow" style={{ maxWidth: '600px', maxHeight: '500px' }} />
                            </Col>
                        </Row>

                        {/* Benefits Section */}
                        <Row className="mb-5">
                            <Col md={6}>
                                <div className="numbered-list-container">
                                    <h3 className="mb-4">Benefits of Solar Screens:</h3>
                                    <ul className="numbered-list">
                                        <li><strong>Energy Savings:</strong> Reduce cooling costs by up to 25% by blocking heat before it enters your home</li>
                                        <li><strong>UV Protection:</strong> Protect your furniture and flooring from fading due to harsh UV rays</li>
                                        <li><strong>Increased Privacy:</strong> Enjoy daytime privacy while maintaining your view to the outside</li>
                                        <li><strong>Glare Reduction:</strong> Eliminate harsh glare on TV and computer screens</li>
                                        <li><strong>Extended HVAC Life:</strong> Reduce strain on your air conditioning system</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md={6}>
                                <h3 className="mb-4"><FontAwesomeIcon icon={faShieldAlt} className="elegant-icon" /> Perfect for Las Vegas Weather</h3>
                                <p>
                                    Las Vegas has some of the harshest sun exposure in the country. With temperatures regularly exceeding 100°F in summer months, solar screens are not just a luxury—they're a necessity for comfortable living and energy efficiency.
                                </p>
                                <p>
                                    Our solar screens block 80-90% of the sun's heat and UV rays before they even reach your window, making them significantly more effective than interior blinds or curtains at keeping your home cool.
                                </p>
                                <div className="icon-card mt-4">
                                    <div className="icon-wrapper">
                                        <FontAwesomeIcon icon={faSun} size="lg" />
                                    </div>
                                    <div className="icon-content">
                                        <h3>Did You Know?</h3>
                                        <p>
                                            Solar screens can reduce the temperature of rooms with direct sun exposure by up to 15 degrees!
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        {/* Our Process Section */}
                        <Row className="my-5">
                            <Col xs={12}>
                                <h2 className="section-title">Our Solar Screen Installation Process</h2>
                                <hr />
                                <p>
                                    At Defined Cleaning, we offer complete custom solar screen solutions. Each screen is built specifically for your window, ensuring a perfect fit and maximum efficiency.
                                </p>

                                <div className="numbered-list-container my-4">
                                    <ul className="numbered-list">
                                        <li><strong>Consultation:</strong> We measure all your windows and discuss color options for both mesh and frames</li>
                                        <li><strong>Custom Building:</strong> We build each screen to your exact window dimensions</li>
                                        <li><strong>Professional Installation:</strong> Our team expertly installs each screen for optimal performance</li>
                                        <li><strong>Quality Check:</strong> We ensure all screens fit properly and operate smoothly</li>
                                        <li><strong>Final Walkthrough:</strong> We review all installed screens with you to ensure your complete satisfaction</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>

                        {/* Color Options Section */}
                        <Row className="my-5">
                            <Col xs={12} md={6}>
                                <h3 className="mb-4"><FontAwesomeIcon icon={faCheckCircle} className="elegant-icon" /> Screen Mesh Color Options</h3>
                                <Image src="/solar-screen-colors-scaled.jpg" fluid className="rounded shadow" />
                                <p className="mt-3">
                                    We offer a variety of mesh colors to complement your home's exterior. Popular choices include black (best for outward visibility), charcoal, brown, beige, and white.
                                </p>
                            </Col>
                            <Col xs={12} md={6}>
                                <h3 className="mb-4"><FontAwesomeIcon icon={faCheckCircle} className="elegant-icon" /> Frame Color Options</h3>
                                <Image src="/screenframecolors-scaled.jpg" fluid className="rounded shadow" />
                                <p className="mt-3">
                                    Our frame colors include white, bronze, tan, champagne, and silver to match your window frames and home exterior.
                                </p>
                            </Col>
                        </Row>

                        {/* Call to Action */}
                        <Row className="my-5">
                            <Col>
                                <div className="modern-cta">
                                    <h2 className="modern-cta-title">Start Saving on Energy Costs Today</h2>
                                    <p>Get a free estimate for custom solar screens for your home</p>

                                    <div>
                                        <Button className="modern-cta-button" href={phoneNumberLink}>
                                            <FontAwesomeIcon icon={faPhone} className="elegant-icon-white" /> Call or Text {phoneNumber}
                                        </Button>

                                        <Button className="modern-cta-button" href="/#screens">
                                            <FontAwesomeIcon icon={faQuoteLeft} className="elegant-icon-white" /> Calculate Screen Pricing
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        {/* FAQ Section */}
                        <Row className="my-5">
                            <Col xs={12}>
                                <h2 className="section-title">Frequently Asked Questions</h2>
                                <hr />

                                <div className="my-4">
                                    <h4><FontAwesomeIcon icon={faCheckCircle} className="elegant-icon" /> How long do solar screens last?</h4>
                                    <p>With proper care, solar screens can last 10-15 years in the Las Vegas climate. The frames may last even longer, with only the mesh needing replacement.</p>

                                    <h4><FontAwesomeIcon icon={faCheckCircle} className="elegant-icon" /> Can I still open my windows with solar screens?</h4>
                                    <p>Yes! Solar screens are installed similar to regular insect screens and do not impede window function.</p>

                                    <h4><FontAwesomeIcon icon={faCheckCircle} className="elegant-icon" /> Do solar screens completely block the view?</h4>
                                    <p>No, you can still see through solar screens from the inside. The view is somewhat darker (similar to wearing sunglasses), but still clear. From outside, it's much more difficult to see in, providing privacy.</p>

                                    <h4><FontAwesomeIcon icon={faCheckCircle} className="elegant-icon" /> How much do solar screens cost?</h4>
                                    <p>Solar screen costs are calculated by square footage. Our standard rate is $10 per square foot, with a minimum charge of $100 per screen. For a precise estimate, we recommend scheduling a free consultation.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </main>

            <Footer />
        </div>
    );
};

export default SolarScreens;