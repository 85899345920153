import { Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from '@mui/material'
import React from 'react'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GridViewIcon from '@mui/icons-material/GridView';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { MoneyOutlined } from '@mui/icons-material';

const SidebarMenu = (props: {
    drawerWidth: number,
    open: boolean
}) => {
    const [open, setOpen] = React.useState(props.open);

    const listItemTextStyle = {
        color: "white"
    }

    return (

        <List>
            <ListItem key="customers" disablePadding>
                <ListItemButton href="/crm/customers">
                    <AccountCircleIcon style={listItemTextStyle} className="mr-2" />
                    <ListItemIcon >
                        {/* The sx opacity open line basically just says to make the text invisible if the menu is closed */}
                        <ListItemText sx={{ opacity: open ? 1 : 0 }} style={listItemTextStyle} primary="Customers" />
                    </ListItemIcon>
                </ListItemButton>
            </ListItem>
            <ListItem key="inventory" disablePadding>
                <ListItemButton href="/crm/inventory">
                    <InventoryIcon style={listItemTextStyle} className="mr-2" />
                    <ListItemIcon>
                        <ListItemText sx={{ opacity: open ? 1 : 0 }} style={listItemTextStyle} primary="Inventory" />
                    </ListItemIcon>
                </ListItemButton>
            </ListItem>

            <ListItem key="screens" disablePadding>
                <ListItemButton href="/crm/screens">
                    <GridViewIcon style={listItemTextStyle} className="mr-2" />
                    <ListItemIcon>
                        <ListItemText sx={{ opacity: open ? 1 : 0 }} style={listItemTextStyle} primary="Solar Screens" />
                    </ListItemIcon>
                </ListItemButton>
            </ListItem>

            <ListItem key="appointments" disablePadding>
                <ListItemButton href="/crm/appointments">
                    <CalendarMonthIcon style={listItemTextStyle} className="mr-2" />
                    <ListItemIcon>
                        <ListItemText sx={{ opacity: open ? 1 : 0 }} style={listItemTextStyle} primary="Appointments" />
                    </ListItemIcon>
                </ListItemButton>
            </ListItem>

            <ListItem key="invoices" disablePadding>
                <ListItemButton href="/crm/invoices">
                    <DescriptionOutlinedIcon style={listItemTextStyle} className="mr-2" />
                    <ListItemIcon>
                        <ListItemText sx={{ opacity: open ? 1 : 0 }} style={listItemTextStyle} primary="Invoices" />
                    </ListItemIcon>
                </ListItemButton>
            </ListItem>

            <ListItem key="finances" disablePadding>
                <ListItemButton href="/crm/finances">
                    <MoneyOutlined style={listItemTextStyle} className="mr-2" />
                    <ListItemIcon>
                        <ListItemText sx={{ opacity: open ? 1 : 0 }} style={listItemTextStyle} primary="Finances" />
                    </ListItemIcon>
                </ListItemButton>
            </ListItem>
        </List>

    )
}


export default SidebarMenu