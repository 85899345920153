import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css';
import Home from './pages/home';
import 'bootstrap/dist/css/bootstrap.min.css';
import { hotjar } from 'react-hotjar'
import ReactGA from 'react-ga'
import LiveChat from 'react-livechat'
import firebase from 'firebase'
import PowerWashing from './pages/powerwashing';
import Survey from './pages/survey';
import Screens from './pages/screens';
import CRMLayout from './pages/crm-layout';
import Appointments from './pages/appointments';
import AppointmentPage from './pages/appointmentPage';
import GutterCleaning from './pages/gutter-cleaning';
import TrackCleaning from './pages/track-cleaning';
import SolarScreens from './pages/solar-screens';
import CarDetailingLasVegas from './pages/car-detailing-las-vegas';
import LasVegas from './pages/service-areas/las-vegas';
import Henderson from './pages/service-areas/henderson';
import Summerlin from './pages/service-areas/summerlin';
import NorthLasVegas from './pages/service-areas/north-las-vegas';
import SpringValley from './pages/service-areas/spring-valley';
import Enterprise from './pages/service-areas/enterprise';
import BoulderCity from './pages/service-areas/boulder-city';
import Mesquite from './pages/service-areas/mesquite';
import Paradise from './pages/service-areas/paradise';
import CentennialHills from './pages/service-areas/centennial-hills';
import Estimator from './components/estimator';

function App() {

  const phoneNumberLink = "tel:7027470901"

  useEffect(() => {
    hotjar.initialize(2197434, 0)
    ReactGA.initialize('UA-84318222-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (firebase.apps.length == 0) {
      // Initialize Cloud Firestore through Firebase
      firebase.initializeApp({
        apiKey: 'AIzaSyCrt_jTKOXaJU1dhclEXuWT7Q1bDu8_Vo8',
        authDomain: 'dephyned-web.firebaseapp.com',
        projectId: 'dephyned-web'
      });
    }
  }, [])

  return (
    <Router>
      <div style={{ backgroundColor: "#dae9f7" }}>
        <LiveChat license='11554178' />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/estimate" element={<Estimator />} />
          <Route path="/home" element={<Home />} />
          <Route path="/screens" element={<Screens />} />
          <Route path="/survey" element={<Survey />} />
          <Route path="/appointments" element={<Appointments />} />
          <Route path="/crm" element={<CRMLayout />} />
          <Route path="/crm/:setting" element={<CRMLayout />} />
          <Route path="/powerwashing" element={<PowerWashing />} />
          <Route path="/gutter-cleaning" element={<GutterCleaning />} />
          <Route path="/track-cleaning" element={<TrackCleaning />} />
          <Route path="/solar-screens" element={<SolarScreens />} />
          <Route path="/car-detailing-las-vegas" element={<CarDetailingLasVegas />} />
          <Route path="/service-areas/las-vegas" element={<LasVegas />} />
          <Route path="/service-areas/henderson" element={<Henderson />} />
          <Route path="/service-areas/summerlin" element={<Summerlin />} />
          <Route path="/service-areas/north-las-vegas" element={<NorthLasVegas />} />
          <Route path="/service-areas/spring-valley" element={<SpringValley />} />
          <Route path="/service-areas/enterprise" element={<Enterprise />} />
          <Route path="/service-areas/boulder-city" element={<BoulderCity />} />
          <Route path="/service-areas/mesquite" element={<Mesquite />} />
          <Route path="/service-areas/paradise" element={<Paradise />} />
          <Route path="/service-areas/centennial-hills" element={<CentennialHills />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
